import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { AuthProvider, useAuth } from './components/AuthContext';
import Signup from './Signup';
import Login from './Login';
import AccountDetails from './AccountDetails';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';
import { Security } from './Security';
import LandingPage from './LandingPage';
import InstallationGuide from './InstallationGuide';
import FAQ from './FAQ';
import PrivacyPolicy from './PrivacyPolicy';

// A wrapper for <Route> that shows the Landing Page
// screen if you're not yet authenticated.
const PrivateRoute = ({ children, ...rest }: any) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={() =>
        user ? (
          children
        ) : (
          <LandingPage />
        )
      }
    />
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <CssBaseline />

        {/* Authenticated routes */}
        <PrivateRoute exact path="/">
          <InstallationGuide />
        </PrivateRoute>
        <PrivateRoute path="/faq">
          <FAQ />
        </PrivateRoute>

        <PrivateRoute path="/account-details">
          <AccountDetails />
        </PrivateRoute>
        <PrivateRoute path="/security">
          <Security />
        </PrivateRoute>

        {/* Unauthenticated routes */}
        <Route path="/enlist" component={Signup} />
        <Route path="/login" component={Login} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route path="/reset-password/:token" component={ResetPassword} />
        <Route path="/verify-email/:token" component={VerifyEmail} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
