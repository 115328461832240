import { isEmpty, trim } from 'lodash';

/**
 * This regex matches only when all the following are true:
 *
 * - callsign 1st character must be alphanumeric
 * - callsign middle characters must be alphanumeric or underscore
 * - callsign last character must be alphanumeric
 * - callsign is 4-32 characters
 */
export const CALLSIGN_RULES = [
  'callsign 1st character must be alphanumeric',
  'callsign middle characters must be alphanumeric or underscore',
  'callsign last character must be alphanumeric',
  'callsign is 4-32 characters',
];
export const CALLSIGN_REGEX = /^(?=.{4,32}$)(?!_)(?!.*_{2})[a-zA-Z0-9_]+(?<!_)$/;

/**
 * This regex matches only when all the following are true:
 *
 * - password must contain 1 number (0-9)
 * - password must contain 1 uppercase letters
 * - password must contain 1 lowercase letters
 * - password must contain 1 non-alpha numeric number
 * - password is 8-32 characters with no space
 */
export const PASSWORD_RULES = [
  'password must contain 1 number (0-9)',
  'password must contain 1 uppercase letters',
  'password must contain 1 lowercase letters',
  'password must contain 1 non-alpha numeric number',
  'password is 8-32 characters with no space',
];
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,32}$/;

export const validateCallsign = (username?: string): boolean => {
  return !!username && !isEmpty(trim(username)) && CALLSIGN_REGEX.test(username);
};

export const validatePassword = (password?: string): boolean => {
  return !!password && !isEmpty(password) && PASSWORD_REGEX.test(password);
};
