import React from 'react';
import {
  Button,
  Typography,
  makeStyles,
  Grid,
  Stepper,
  StepLabel,
  Paper,
  StepContent,
  Step,
  StepIconProps,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import BuildIcon from '@material-ui/icons/BuildTwoTone';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadTwoTone';
import EditIcon from '@material-ui/icons/EditTwoTone';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FolderIcon from '@material-ui/icons/FolderTwoTone';
import LaunchIcon from '@material-ui/icons/LaunchTwoTone';
import LockOpenIcon from '@material-ui/icons/LockOpenTwoTone';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import OpenWithIcon from '@material-ui/icons/OpenWithTwoTone';
import SaveIcon from '@material-ui/icons/SaveTwoTone';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartTwoTone';
import WarningIcon from '@material-ui/icons/Warning';
import CopyToClipboard from './components/CopyToClipboard';
import { servicesUrl, downloadUrlCL142579, downloadUrl7zip } from './urls';

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  card : {
    background: `rgba(0, 0, 0, 0.75)`,
  },
  cardContent: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    marginTop: theme.spacing(1),
    display: 'block',
  },
  logo: {
    maxWidth: '100%',
    width: 250,
  },
  resetContainer: {
    background: `rgba(0, 0, 0, 0.0)`,
    padding: theme.spacing(3),
  },
  stepper : {
    background: `rgba(0, 0, 0, 0.0)`,
  },
}));

const meteorUriLine = `MeteorUri = ${servicesUrl}`;

const getSteps = () => {
  return [
    'Download the client',
    'Extract the client using 7zip',
    'Go to the client Binaries folder',
    'Open the file StormConsumerSettings.ini for editing',
    'Edit the MeteorUri line',
    'Save the file',
    'Launch the game',
    'Login to the game',
    'Play offline game modes',
    'Fill your Garage',
    'Go shopping'
  ];
}

const ensureElement = (content: string | JSX.Element) => (
  typeof content === 'string'
  ? <Typography variant='body2'>{content}</Typography>
  : content
);

const getStepContent = (step: number) => {
  switch (step) {
    case 0:
      return (
        <React.Fragment>
          <Grid item xs={12}>
            <Button
              variant='contained'
              color='secondary'
              href={downloadUrlCL142579}
              target='_blank'
            >
              Download game client
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='body1'
              color='error'
            >
              <WarningIcon />
              You <strong>MUST</strong> use this client, not any other version!
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case 1:
      return (
        <React.Fragment>
          <Grid item xs={12}>
            <Button
              variant='contained'
              color='default'
              href={downloadUrl7zip}
              target='_blank'
            >
              Download 7zip
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2'>
              Extract it somewhere sensible e.g. C:\Games\
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case 2:
      return `This is located at Hawken\\Binaries\\Win32 relative to where you extracted the client,
        e.g. following the above example, C:\\Games\\Hawken\\Binaries\\Win32`;
    case 3:
      return `Right click the file and select Edit`;
    case 4:
      return (
        <React.Fragment>
          <Grid container spacing={3} >
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Change the line to appear as follows"
                value={meteorUriLine}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <CopyToClipboard>
                      {({ copy }) => (
                        <IconButton
                          aria-label="copy to clipboard"
                          onClick={() => copy(meteorUriLine)}
                          edge="end"
                        >
                          <FileCopyIcon />
                        </IconButton>
                      )}
                    </CopyToClipboard>
                  </InputAdornment>,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </React.Fragment>
      );
    case 5:
      return '';
    case 6:
      return `The game is located at Hawken\\Binaries\\Win32\\HawkenGame-Win32-Shipping.exe`;
    case 7:
      return `Use your enlisted email/password`;
    case 8:
      return `Go to Training to play the offline game modes including Offline Team Deathmatch, Offline
          Bot Training (waves), and VR Training (basic training). Map Exploration is also
          available.`;
    case 9:
      return `Get more mechs, weapons, internals, items in the Garage. You can use XP Stat Overflow to
          level up your mech.`;
    case 10:
      return `You can buy even more stuff in the Store! Don't worry about credits/money as you'll find
          you have an ample balance of credits.`;
    default:
      return `Unknown step`;
  }
}

function CustomStepIcon(props: StepIconProps) {
  const icons: { [index: string]: React.ReactElement } = {
    1: <CloudDownloadIcon />,
    2: <OpenWithIcon />,
    3: <FolderIcon />,
    4: <LaunchIcon />,
    5: <EditIcon />,
    6: <SaveIcon />,
    7: <LaunchIcon />,
    8: <LockOpenIcon />,
    9: <OfflineBoltIcon />,
    10: <BuildIcon />,
    11: <ShoppingCartIcon />,
  };

  return (
    <div>
      {icons[String(props.icon)]}
    </div>
  );
}

const Guide = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Grid item xs={12}>
      <Typography variant="body1">
        Please follow these steps to get up and running.
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
            <StepContent>
              {ensureElement(getStepContent(index))}
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - now you&apos;re ready!</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </Grid>
  );
};

export default Guide;
