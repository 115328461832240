import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { UnauthenticatedContainer } from './components/UnauthenticatedContainer';

const contactEmail = 'info@hawakening.com';
const dataLocations = [
  'Montreal, Quebec, Canada',
];

const listItemsFor = (list: string[]) => list.map((item, i) => <li key={i}>{item}</li>);

const obfuscateString = (email: string) => email.split('').map((c, i) => <span key={i}>{c}</span>);

const useStyles = makeStyles(theme => ({
  alert: {
    marginTop: theme.spacing(3),
  },
  aLink: {
    color: '#ffd54f',
    textDecoration: 'none',
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  card: {
    background: `rgba(0, 0, 0, 0.75)`,
    marginTop: theme.spacing(3),
  },
  cardHeader: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  link: {
    marginTop: theme.spacing(1),
    display: 'block',
  },
}));

const HomeLink = React.forwardRef<RouterLink, any>((props, ref) => (
  <RouterLink to="/" {...props} ref={ref} />
));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <UnauthenticatedContainer>
      <Typography variant="h5">Privacy Policy</Typography>
      <Divider className={classes.divider} />
      <Card className={classes.card}>
        <CardHeader subheader="Hawakening Privacy Policy" className={classes.cardHeader} />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Typography variant='h5'>What data do we collect?</Typography>
          <Typography variant='body2'>Hawakening collects the following data:</Typography>
          <ul>
            <li>email address</li>
            <li>country</li>
          </ul>

          <Typography variant='h5'>How do we collect your data?</Typography>
          <Typography variant='body2'>
            You directly provide Hawakening with all of the data we collect. We collect data and
            process data when you:
          </Typography>
          <ul>
            <li>Register online</li>
            <li>View your profile</li>
          </ul>

          <Typography variant='body2'>
            Hawakening may also receive location data from:
          </Typography>
          <ul>
            <li>hostip.info (IP location API)</li>
          </ul>

          <Typography variant='h5'>How will we use your data?</Typography>
          <Typography variant='body2'>
            Hawakening collects your data so that we can:
          </Typography>
          <ul>
            <li>Provide a Hawken game client account for login</li>
            <li>Send you emails for email/account verification and password resets</li>
            <li>Optimise performance based on your registered location (country)</li>
            <li>Abide by any applicable data privacy laws specific to your country</li> 
          </ul>

          <Typography variant='h5'>How do we store your data?</Typography>
          <Typography variant='body2'>
            Hawakening securely stores your data at:
          </Typography>
          <ul>
            {listItemsFor(dataLocations)}
          </ul>
          <Typography variant='body2'>
            The data is stored in databases located on private networks only accessible by
            Hawakening.
          </Typography>
          <Typography variant='body2'>
            All data is transmitted using end-to-end SSL/TLS encryption using TLS 1.2+,
            SHA256 and strong ciphers.
          </Typography>

          <Typography variant='h5'>Marketing</Typography>
          <Typography variant='body2'>
            Hawakening will not contact you for marketing purposes.
          </Typography>

          <Typography variant='h5'>What are your data protection rights?</Typography>
          <Typography variant='body2'>
            Hawakening would like to make sure that you are fully aware of all your data
            protection rights. Every user is entitled to the following:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="The right to access"
                secondary="You have the right to request Hawakening for copies of your
                  personal data."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="The right to rectification"
                secondary="You have the right to request that Hawakening corrects any
                  information you believe is inaccurate. You also have the right to request
                  Hawakening to complete information you believe is incomplete."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="The right to erasure"
                secondary="You have the right to request Hawakening for erase your personal
                  data, under certain conditions."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="The right to restrict processing"
                secondary="You have the right to object to Hawakening's processing of your
                  personal data, under certain conditions."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="The right to data portability"
                secondary="You have the right to request that Hawakening transfer the data
                  that we have collected to another organisation, or directly to you, under
                  certain conditions."
              />
            </ListItem>
          </List>
          <Typography variant='body2'>
            If you make a request, we have one month to respond to you. If you would like to
            exercise any of these rights, please contact us at our email: 
            {obfuscateString(contactEmail)}
          </Typography>

          <Typography variant='h5'>What are cookies?</Typography>
          <Typography variant='body2'>
            Cookies are text files placed on your computer to collect standard Internet log
            information and visitor behaviour information. When you visit our website, we
            may collect information from you automatically through cookies or similar
            technology.
          </Typography>
          <Typography variant='body2'>
            For further information, visit <a
              className={classes.aLink}
              href="https://allaboutcookies.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              allaboutcookies.org
            </a>
          </Typography>

          <Typography variant='h5'>How do we use cookies?</Typography>
          <Typography variant='body2'>
            Hawakening uses cookies in a limited/minimal way for the purposes of using the
            website, including:
          </Typography>
          <ul>
            <li>Keeping you signed in</li>
          </ul>

          <Typography variant='h5'>What types of cookies do we use?</Typography>
          <Typography variant='body2'>
            There are a number of different types of cookies, however, our website uses:
          </Typography>
          <ul>
            <li>Functionality - Hawakening uses these cookies so that we recognise you on
              our website.
            </li>
          </ul>

          <Typography variant='h5'>How to manage cookies</Typography>
          <Typography variant='body2'>
            You can set your browser not to accept cookies, and the above website tells you how to
            remove cookies from your browser. However, in a few cases, some of our website features
            may not function as a result.
          </Typography>

          <Typography variant='h5'>Changes to our privacy policy</Typography>
          <Typography variant='body2'>
            Hawakening keeps its privacy policy under regular review and places any updates on this
            web page. This privacy policy was last updated on 2nd August 2020.
          </Typography>

          <Typography variant='h5'>How to contact us</Typography>
          <Typography variant='body2'>
            If you have any questions about Hawakening's privacy policy, the data we hold on
            you, or you would like to exercise one of your data protection rights, please do
            not hesitate to contact us. Email us at: {obfuscateString(contactEmail)}
          </Typography>
          <Divider className={classes.divider} />
          <Link component={HomeLink} className={classes.link}>
            Go Home
          </Link>
        </CardContent>
      </Card>
    </UnauthenticatedContainer>
  );
};

export default PrivacyPolicy;
