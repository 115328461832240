import React from 'react';
import {
  Typography,
  makeStyles,
  Card,
  CardContent,
  Grid,
  Divider,
  CardHeader,
} from '@material-ui/core';
import Guide from './Guide';
import { AuthenticatedContainer } from './components/AuthenticatedContainer';

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  card : {
    background: `rgba(0, 0, 0, 0.75)`,
  },
  cardContent: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  cardHeader: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    marginTop: theme.spacing(1),
    display: 'block',
  },
}));

const InstallationGuide = () => {
  const classes = useStyles();

  return (
    <AuthenticatedContainer>
      <Typography variant="h5">Installation Guide</Typography>
      <Divider className={classes.divider} />
      <Card className={classes.card}>
        <CardHeader subheader="You're almost ready..." className={classes.cardHeader} />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">Welcome pilot, glad you joined our ranks.</Typography>
            </Grid>
            <Guide />
          </Grid>
        </CardContent>
      </Card>
    </AuthenticatedContainer>
  );
};

export default InstallationGuide;
