import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Card, CardContent, Divider, Grid, Typography, makeStyles, Link } from '@material-ui/core';
import { accountsRest } from './accounts';
import { UnauthenticatedContainer } from './components/UnauthenticatedContainer';

const useStyles = makeStyles(theme => ({
  card : {
    background: `rgba(0, 0, 0, 0.75)`,
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    marginTop: theme.spacing(1),
    display: 'block',
  },
  logo: {
    maxWidth: '100%',
    width: 250,
  },
}));

const HomeLink = React.forwardRef<RouterLink, any>((props, ref) => (
  <RouterLink to="/" {...props} ref={ref} />
));

const VerifyEmail = () => {
  const classes = useStyles();
  const match = useParams<{ token: string }>();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const verifyEmail = async () => {
    try {
      await accountsRest.verifyEmail(match.token);
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    verifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UnauthenticatedContainer>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img src="/logo.png" alt="Logo" className={classes.logo} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Email verification</Typography>
              {error &&
                <Typography color="error">{error!}</Typography>
              }
              {success &&
                <Typography color="primary">Your email has been verified</Typography>
              }
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Link component={HomeLink} className={classes.link}>
            Go Home
          </Link>
        </CardContent>
      </Card>
    </UnauthenticatedContainer>
  );
};

export default VerifyEmail;
