import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  textBox: {
    padding: theme.spacing(5),
    marginBottom: theme.spacing(8),
    height: '80vh',
  },
  text: {
    fontFamily: 'kremlinregular',
    color: '#d8fefe',
    marginBottom: theme.spacing(3),
    fontSize: '2.0rem',
    WebkitTextStrokeColor: 'grey',
    WebkitTextStrokeWidth: '0.01rem',
    '@media (min-width:600px)': {
      fontSize: '2.8rem',
      WebkitTextStrokeWidth: '0.02rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '4.8rem',
      WebkitTextStrokeWidth: '0.03rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '6.4rem',
      WebkitTextStrokeWidth: '0.05rem',
    },
  },
  ctaButton: {
    fontSize: '1.0rem',
    '@media (min-width:600px)': {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '3.2rem',
    },
    fontWeight: 400,
  },
}));

interface LandingPageProps {}

export const LandingPage: React.FC<LandingPageProps> = () => {
  const classes = useStyles();

  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        className={classes.textBox}
      >
        <Typography variant="h1" className={classes.text}>
          HAWAkENING IS HERE
        </Typography>
        <Button
          component={Link}
          variant="contained"
          color="primary"
          className={classes.ctaButton}
          to="/login"
        >
          Sign in
        </Button>
      </Box>
    </Box>
  );
};

export default LandingPage;
