import React, { useState } from 'react';
import {
  Typography,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
  CardActions,
  Button,
  Snackbar,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SendIcon from '@material-ui/icons/Send';
import { useFormik, FormikErrors } from 'formik';
import { User } from '@accounts/types';
import { accountsClient, accountsRest } from './accounts';
import { AuthenticatedContainer } from './components/AuthenticatedContainer';
import { useAuth } from './components/AuthContext';
import { countryToFlag, countries } from './utils/location';

const useStyles = makeStyles(theme => ({
  alert: {
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  card: {
    background: `rgba(0, 0, 0, 0.75)`,
    marginTop: theme.spacing(3),
  },
  cardHeader: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  cardActions: {
    padding: theme.spacing(3),
  },
  emailItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emailItemPart: {
    display: 'flex',
  },
  emailItemDot: {
    marginRight: theme.spacing(2),
  },
}));

interface AccountDetailsValues {
  username: string;
}

interface CustomUser extends User {
  countryCode?: string;
}

const AccountDetails = () => {
  const classes = useStyles();
  const [message, setMessage] = useState<{ type: 'success' | 'error'; message: string }>();

  const { fetchUser, user } = useAuth();

  const formik = useFormik<AccountDetailsValues>({
    initialValues: {
      username: user?.username ?? '',
    },
    validate: values => {
      const errors: FormikErrors<AccountDetailsValues> = {};
      if (!values.username) {
        errors.username = 'Required';
      } else if (values.username === user?.username ?? '') {
        errors.username = 'Same value as current';
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const tokens = await accountsClient.refreshSession();
        if (tokens) {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
            method: 'PUT',
            headers: {
              Authorization: tokens ? 'Bearer ' + tokens.accessToken : '',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: values.username,
            }),
          });
          if (res.status === 200) {
            setMessage({ type: 'success', message: 'Profile updated' });
          } else {
            const body = await res.json();
            setMessage({ type: 'error', message: body.error });
          }
          await fetchUser();
        }
      } catch (error) {
        setMessage({ type: 'error', message: error.message });
      }
      setSubmitting(false);
    },
  });

  const onResendEmail = async (address: string) => {
    try {
      await accountsRest.sendVerificationEmail(address);
      setMessage({ type: 'success', message: 'Verification email sent' });
    } catch (error) {
      setMessage({ type: 'error', message: error.message });
    }
  };

  if (!user) {
    return <AuthenticatedContainer>Loading ...</AuthenticatedContainer>;
  }
  const userExtended = user as CustomUser;
  return (
    <AuthenticatedContainer>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!message}
      >
        <Alert
          severity={message?.type}
          className={classes.alert}
          onClose={() => setMessage(undefined)}
        >
          {message?.message}
        </Alert>
      </Snackbar>
      <Typography variant="h5">Account Details</Typography>
      <Divider className={classes.divider} />
      <Card className={classes.card}>
        <form onSubmit={formik.handleSubmit}>
          <CardHeader subheader="Profile" className={classes.cardHeader} />
          <Divider />
          <CardContent className={classes.cardContent}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Callsign"
                  variant="outlined"
                  fullWidth={true}
                  id="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.username && formik.touched.username)}
                  helperText={formik.touched.username && formik.errors.username}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={classes.cardActions}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={formik.isSubmitting}
            >
              Update profile
            </Button>
          </CardActions>
        </form>
      </Card>

      <Card className={classes.card}>
        <CardHeader subheader="Email" className={classes.cardHeader} />
        <Divider />
        <CardContent className={classes.cardContent}>
          {user.emails &&
            user.emails.map(email => (
              <div key={email.address} className={classes.emailItem}>
                <div className={classes.emailItemPart}>
                  <Tooltip
                    arrow
                    placement="top-start"
                    title={
                      email.verified ? 'Your email is verified' : 'You need to verify your email'
                    }
                  >
                    <FiberManualRecordIcon
                      className={classes.emailItemDot}
                      color={email.verified ? 'primary' : 'error'}
                    />
                  </Tooltip>
                  <Typography>{email.address}</Typography>
                </div>
                {!email.verified && (
                  <Tooltip arrow placement="top-end" title="Resend verification email">
                    <IconButton aria-label="Send" onClick={() => onResendEmail(email.address)}>
                      <SendIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            ))}
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardHeader subheader="Location" className={classes.cardHeader} />
        <Divider />
        <CardContent className={classes.cardContent}>
          {userExtended.countryCode &&
            <Typography
            >
              Country: <span>{countryToFlag(userExtended.countryCode)}</span> {
                countries.find(c => c.code === userExtended.countryCode)?.label
              } ({userExtended.countryCode})
            </Typography>
          }
        </CardContent>
      </Card>
    </AuthenticatedContainer>
  );
};

export default AccountDetails;
