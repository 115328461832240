import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Router from './Router';

import background from './components/bg-infiltrator.jpg';

const theme = createMuiTheme({
  shape: {
    borderRadius: 0,
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#ffd54f',
    },
    secondary: {
      main: '#ba68c8',
    },
  },
  typography: {
    fontFamily: [
      'Monda',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundAttachment: 'fixed',
          backgroundImage: `url(${background})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        },
      },
    },
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Router />
  </MuiThemeProvider>,
  document.getElementById('root') as HTMLElement
);
