import React, { useState, useEffect } from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Typography,
  makeStyles,
  CardContent,
  Card,
  Divider,
  Link,
  Grid,
  TextField,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Snackbar,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { useFormik, FormikErrors } from 'formik';
import { accountsPassword } from './accounts';
import { UnauthenticatedContainer } from './components/UnauthenticatedContainer';
import Reaptcha from 'reaptcha';

import {
  CALLSIGN_RULES,
  PASSWORD_RULES,
  validateCallsign,
  validatePassword
} from './utils/common-validators';
import { useAuth } from './components/AuthContext';
import { servicesUrl } from './urls';
import { countries, countryToFlag, CountryType } from './utils/location';

const recaptchaSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_SITE_KEY || 'MISSING-KEY';

const useStyles = makeStyles(theme => ({
  alert: {
    marginTop: theme.spacing(3),
  },
  aLink: {
    color: '#ffd54f',
    textDecoration: 'none',
  },
  card: {
    background: `rgba(0, 0, 0, 0.75)`,
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  logo: {
    maxWidth: '100%',
    width: 250,
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

const arrayToListItems = (rules: string[]) => rules.map((rule, i) => <li key={i}>{rule}</li>);

const LogInLink = React.forwardRef<RouterLink, any>((props, ref) => (
  <RouterLink to="/login" {...props} ref={ref} />
));

interface SignupValues {
  username: string;
  email: string;
  password: string;
  countryCode: string;
  communityProjectAccepted: boolean;
  captchaVerified: boolean;
  personalDataUsageAccepted: boolean;
}

const Signup = ({ history }: RouteComponentProps<{}>) => {
  const classes = useStyles();
  const [error, setError] = useState<string | undefined>();
  const [isLoaded, setIsLoaded] = useState(false);

  const { fetchUser } = useAuth();

  const onVerify = async (recaptchaResponse: any) => {
    try {
      const res = await fetch(`${servicesUrl}/recaptcha/verify/v2`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          response: recaptchaResponse,
        }),
      });
      const result = await res.json();
      if (!!result.success) {
        formik.setFieldValue('captchaVerified', true);
      }
    } catch (error) {
      formik.setFieldValue('captchaVerified', false);
    }
  };

  const handleAutocompleteChange = (event: object, country: CountryType | null) => {
    formik.setFieldValue('countryCode', country?.code || undefined);
  }

  const formik = useFormik<SignupValues>({
    initialValues: {
      username: '',
      email: '',
      password: '',
      countryCode: '',
      communityProjectAccepted: false,
      captchaVerified: false,
      personalDataUsageAccepted: false,
    },
    validate: values => {
      const errors: FormikErrors<SignupValues> = {};
      if (!values.username) {
        errors.username = 'Required';
      } else if (!validateCallsign(values.username)) {
        errors.username = 'Invalid callsign';
      }
      if (!values.email) {
        errors.email = 'Required';
      }
      if (!values.password) {
        errors.password = 'Required';
      } else if (!validatePassword(values.password)) {
        errors.password = 'Invalid password';
      }
      if (!values.countryCode) {
        errors.countryCode = 'Required';
      }
      if (!values.communityProjectAccepted) {
        errors.communityProjectAccepted = 'Required';
      }
      if (!values.captchaVerified && process.env.NODE_ENV === 'production') {
        errors.captchaVerified = 'Required';
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const user = await accountsPassword.createUser({
          username: values.username,
          email: values.email,
          password: values.password,
          countryCode: values.countryCode,
          communityProjectAccepted: values.communityProjectAccepted,
          personalDataUsageAccepted: values.personalDataUsageAccepted,
        });
        if (!user.hasOwnProperty('userId')) {
          throw new Error('User creation failed');
        }
        // reload `user`
        await fetchUser();
        history.push('/');
      } catch (error) {
        setError(error.message);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!isLoaded && !formik.values.countryCode) {
      fetch(`https://api.hostip.info/country.php`)
        .then(res => res.text())
        .then(
          (result) => {
            formik.setFieldValue('countryCode', result);
            setIsLoaded(true);
          },
          (error) => {
            setIsLoaded(true);
            console.log(`Country code API: ${error.message}`);
          }
        )
        .catch(
          (error) => {
            setIsLoaded(true);
            console.log(`Country code API: ${error.message}`);
          }
        );
    }
  });

  if (!isLoaded) {
    return <div>Loading...</div>
  }
  return (
    <UnauthenticatedContainer>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!formik.errors.captchaVerified && !!formik.touched.captchaVerified}
      >
        <Alert
          severity='error'
          className={classes.alert}
          onClose={() => setError(undefined)}
        >
          Human verification: {formik.errors.captchaVerified}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!error}
      >
        <Alert
          severity='error'
          className={classes.alert}
          onClose={() => setError(undefined)}
        >
          {error}
        </Alert>
      </Snackbar>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <img src="/logo.png" alt="Logo" className={classes.logo} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Enlist new pilot</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label="Callsign"
                  variant="outlined"
                  fullWidth={true}
                  id="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.username && formik.touched.username)}
                  helperText={formik.touched.username && formik.errors.username}
                />
                <details>
                  <summary>Show callsign rules</summary>
                  <ul>
                    {arrayToListItems(CALLSIGN_RULES)}
                  </ul>
                </details>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth={true}
                  id="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.email && formik.touched.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth={true}
                  type="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.password && formik.touched.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
                <details>
                  <summary>Show password rules</summary>
                  <ul>
                    {arrayToListItems(PASSWORD_RULES)}
                  </ul>
                </details>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id='countryCode'
                  options={countries as CountryType[]}
                  classes={{
                    option: classes.option,
                  }}
                  disableClearable={true}
                  value={countries.find(c => c.code === formik.values.countryCode)}
                  onChange={handleAutocompleteChange}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{countryToFlag(option.code)}</span>
                      {option.label} ({option.code})
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      name='countryCode'
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.countryCode && formik.touched.countryCode)}
                      helperText={formik.touched.countryCode && formik.errors.countryCode}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="communityProjectAccepted"
                      checked={formik.values.communityProjectAccepted}
                      onChange={() => formik.setFieldValue(
                        'communityProjectAccepted',
                        !formik.values.communityProjectAccepted
                      )}
                      name="communityProjectAccepted"
                      color="primary"
                      required={true}
                    />
                  }
                  label="I accept that this is a community project that's in no way associated with
                    505 Games, DR Studios, and Digital Bros"
                />
                <FormHelperText>
                  {!formik.values.communityProjectAccepted && formik.errors.communityProjectAccepted}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="personalDataUsageAccepted"
                      checked={formik.values.personalDataUsageAccepted}
                      onChange={() => formik.setFieldValue(
                        'personalDataUsageAccepted',
                        !formik.values.personalDataUsageAccepted
                      )}
                      name="personalDataUsageAccepted"
                      color="primary"
                      required={true}
                    />
                  }
                  // label="I accept that my personal data will be used to allow playing the game and
                  //   optimise performance"
                  label={
                    <React.Fragment>
                      <Typography variant='body1'>
                        I accept that my personal data will be used to allow playing the game and
                        optimise performance, according to the <a
                          className={classes.aLink}
                          href="/privacy-policy"
                          target="_blank"
                        >privacy policy</a>.
                      </Typography>
                    </React.Fragment>
                  }
                />
                <FormHelperText>
                  {!formik.values.communityProjectAccepted && formik.errors.communityProjectAccepted}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <Reaptcha sitekey={recaptchaSiteKey} onVerify={onVerify} theme="dark"/>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  Enlist
                </Button>
              </Grid>
            </Grid>
          </form>
          <Divider className={classes.divider} />
          <Link component={LogInLink}>Already have an account?</Link>
        </CardContent>
      </Card>
    </UnauthenticatedContainer>
  );
};

export default Signup;
