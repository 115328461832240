import React from 'react';
import {
  Typography,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import { AuthenticatedContainer } from './components/AuthenticatedContainer';
import { discordSupportAndBugReportUrl, servicesUrl } from './urls';


interface FAQListItem {
  primary: string | JSX.Element;
  secondary: string | JSX.Element;
}

const questionListItem = (item: FAQListItem, key: any) => (
  <ListItem key={key}>
    <ListItemIcon>
      <HelpIcon />
    </ListItemIcon>
    <ListItemText
      primary={item.primary}
      secondary={item.secondary}
    />
  </ListItem>
);

const infoListItem = (item: FAQListItem, key: any) => (
  <ListItem key={key}>
    <ListItemIcon>
      <InfoIcon />
    </ListItemIcon>
    <ListItemText
      primary={item.primary}
      secondary={item.secondary}
    />
  </ListItem>
);

const useStyles = makeStyles(theme => ({
  alert: {
    marginTop: theme.spacing(3),
  },
  aLink: {
    color: '#ffd54f',
    textDecoration: 'none',
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  card: {
    background: `rgba(0, 0, 0 , 0.75)`,
    marginTop: theme.spacing(3),
  },
  cardHeader: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  cardContent: {
    padding: theme.spacing(3),
  },
}));

const generalQuestions = [
  {
    primary: "What game modes are available?",
    secondary: `Right now, only Offline game modes are available. These can be accessed by
      going to Training and then selecting one of them: VR Training (basic training),
      Offline Team Deathmatch (you and bots vs bots), and Offline Bots Training (you and
      bots vs waves). You can also Explore Maps from the Play section.`,
  },
  {
    primary: "When will online play be available?",
    secondary: `Right now, we don't really know. There is a team working on it but they
      need to write the game servers from scratch, so it will take a while. We will update
      the community when online play will be available.`,
  },
  {
    primary: "Can I change my loadout/buy in game items/have my own user?",
    secondary: `Yes, now you start off with just a default mech (Assault) in your garage,
      but a healthy "bank balance" of credits to buy all the mechs, items,
      internals, parts, paint, emblems, etc. that you want. Your garage is saved against
      your enlisted pilot profile, so will be as you left it when you log back into the
      game.`,
  },
  {
    primary: "Will feature X or feature Y be available?",
    secondary: `If it was a part of the original Hawken, then probably yes. Otherwise,
      probably not.`,
  },
  {
    primary: "Who is working on the new revival project?",
    secondary: `That would be Team HAWaKENing.`,
  },
  {
    primary: "I have a Steam client, will this work?",
    secondary: `Unfortunately not. You need to use the version provided above.`,
  },
  {
    primary: "Which build should I be using?",
    secondary: `You should be using build CL142579. There are other builds that can work in theory,
      however this is the build that is currently being supported/developed on by Team HAWaKENing.`,
  },
];



const buildsAndDescriptionsInfo = [
  {
    primary: `CL129423 (MeteorUri = http://play.hawakening.com)`,
    secondary: `This build is older and no longer available. It is not recommended to use this
      client as it has numerous bugs and glitches. Support for this build will likely be dropped in
      the future.`,
  },
  {
    primary: `CL129587 (MeteorUri = http://test1.hawakening.com)`,
    secondary: `This build is older. It is not recommended to use this client as it also has
      numerous bugs and glitches.`,
  },
  {
    primary: `CL142579 (MeteorUri = ${servicesUrl})`,
    secondary: `This build is the one that is currently supported. It features the Explore Maps
      option, uses more current stats for weapons/mechs and doesn't have any of the graphical issues
      featured in previous builds. The Offline Bots Training mode is reported by some not to launch
      the waves (but this seems to be random and should succeed if you retry).`,
  },
]

const FAQ = () => {
  const classes = useStyles();

  const errorsAndGlitchesQuestions = [
    {
      primary: `I'm getting "Logon Failed: Online Service Unavailable", what should I do?`,
      secondary: `Make sure you copy the MeteorUri line exactly as it is written. The MeteorUri should
        begin https:// and not http://, and should not have any extra whitespace at the end of the
        line. We recommend copy-pasting the line from the Installation Guide to avoid typos and
        errors.`,
    },
    {
      primary: `I'm getting "Your version of Hawken needs updating", what should I do?`,
      secondary: `Make sure you are using the correct MeteorUri for your build. This can be found in
        the Installation Guide.`,
    },
    {
      primary: `I'm getting "Mech Launch Failure", what should I do?`,
      secondary: `Make sure you are in the right game modes (Offline TDM or Offline Bots Training). If
        so, try going into Settings and back out again. Sometimes the client can be a bit stubborn
        before letting you in. This is a known issue with no real fix.`,
    },
    {
      primary: `Sometimes the bots in Offline Bots Training don't start, what should I do?`,
      secondary: `This is a known bug for build CL142579. This is a known issue with no real fix. You
        should be able to play if you simply try again.`,
    },
    {
      primary: `I'm getting some other error/glitch/bug, what should I do?`,
      secondary: (
        <Typography variant='body2'>
          Ask in <a className={classes.aLink} href={discordSupportAndBugReportUrl}>
            Support and Bug Report
            </a> to see if anyone else has had a similar issue. Double check that you've copied all
          the fields correctly and do not have any left over files from old Hawken installations
          (these can be found in My Documents\Hawken).
        </Typography>
      )
    },
  ];

  return (
    <AuthenticatedContainer>
      <Typography variant="h5">Frequently Asked Questions</Typography>
      <Divider className={classes.divider} />
      <Card className={classes.card}>
        <CardHeader subheader="General Questions" className={classes.cardHeader} />
        <Divider />
        <CardContent className={classes.cardContent}>
          <List aria-label="general questions">
            {generalQuestions.map((item, i) => questionListItem(item, i))}
          </List>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardHeader subheader="Errors and Glitches" className={classes.cardHeader} />
        <Divider />
        <CardContent className={classes.cardContent}>
          <List aria-label="errors and glitches">
            {errorsAndGlitchesQuestions.map((item, i) => questionListItem(item, i))}
          </List>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardHeader subheader="Builds and Descriptions" className={classes.cardHeader} />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Typography
            variant='body2'
          >
            Over the years here have been many different builds of Hawken. The following builds have
            been used historically by this community.
          </Typography>
          <List aria-label="builds and descriptions">
            {buildsAndDescriptionsInfo.map((item, i) => infoListItem(item, i))}
          </List>
          <Typography
            variant='body2'
          >
            It is important that you use the correct MeteorUri for the build you decide to run.
            Mixing and matching builds and MeteorUris will not work.
          </Typography>
        </CardContent>
      </Card>
    </AuthenticatedContainer>
  );
};

export default FAQ;
