import React, { useCallback, useEffect, useState } from 'react';
import { User } from '@accounts/types';
import { accountsClient } from '../accounts';

const AuthContext = React.createContext<{
  user?: User;
  fetchUser: () => Promise<void>;
  loginWithService: (service: string, credentials: any) => Promise<void>;
  logout: () => Promise<void>;
}>({
  fetchUser: async () => {},
  loginWithService: async () => {},
  logout: async () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, setState] = useState<{ user?: User; loading: boolean }>({ loading: true });

  const fetchUser = useCallback(async () => {
    const accountsUser = await accountsClient.getUser();
    setState({ loading: false, user: accountsUser });
  }, []);

  const loginWithService = useCallback(async (service: string, credentials: any) => {
    const result = await accountsClient.loginWithService(service, credentials);

    if (result) {
      setState({ loading: false, user: result.user });
    }
  }, []);

  const logout = useCallback(async () => {
    await accountsClient.logout();
    setState({ loading: false, user: undefined });
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  // If we need to refresh the tokens, it will show a fullscreen loader
  if (state.loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user: state.user, loginWithService, logout, fetchUser }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
